<template>
        <section id="OurValues" class="about-area pb-200">
        <div class="container" style="text-align: justify;">
            <div class="row">
                <div class="col-lg-5">
                    <div class="faq-content mt-45">
                        <div class="about-title">
                            <h4 class="sub-title">{{ $t('Contents.OurValues') }}</h4>
                            <!-- <h4 class="title">Frequently Asked Questions <br> About Our Site</h4> -->
                        </div>
                        <!-- faq title -->
                        <div class="about-accordion">
                            <div class="accordion" id="accordionExample">
                                <!-- <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">{{ $t('Contents.AboutUs')}}</a>
                                    </div>

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample" >
                                        <div class="card-body">
                                            <p class="text"> {{ $t('Contents.AboutUsDetails') }}</p>
                                            <p class="text"> {{ $t('Contents.AboutUsDetails2') }}</p>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- card About-->
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <a href="#" class="collapsed " data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">{{ $t('Contents.Mission') }} </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p class="text">{{ $t('Contents.MissionDetails') }} </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- card  Mission-->
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> {{ $t('Contents.Vision') }} </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p class="text"> {{ $t('Contents.VisionDetails') }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- card Vision-->
                                <div class="card">
                                    <div class="card-header" id="headingFore">
                                        <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapseFore" aria-expanded="false" aria-controls="collapseFore">{{ $t('Contents.OurValues') }}</a>
                                    </div>
                                    <div id="collapseFore" class="collapse" aria-labelledby="headingFore" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <ul >
                                                <li> <i class="lni lni-bolt"></i> {{ $t('Contents.OurValuesDetails.Integrity') }} </li>
                                                <li> <i class="lni lni-bolt"></i> {{ $t('Contents.OurValuesDetails.Respect') }} </li>
                                                <li> <i class="lni lni-bolt"></i> {{ $t('Contents.OurValuesDetails.ConstantImprovement') }} </li>
                                                <li> <i class="lni lni-bolt"></i> {{ $t('Contents.OurValuesDetails.CompleteAccountability') }} </li>
                                                <li> <i class="lni lni-bolt"></i> {{ $t('Contents.OurValuesDetails.CommitmentToBusinessEthics') }} </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- card -->
                                <!-- <div class="card">
                                    <div class="card-header" id="headingFive">
                                        <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Frequently Asked Question Five</a>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p class="text">Morbi vehicula arcu et pellentesque tincidunt. Nunc ligula nulla, lobortis a elementum non, vulputate ut arcu. Aliquam erat volutpat. Nullam lacinia felis.</p>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- card -->
                            </div>
                        </div>
                        <!-- faq accordion -->
                    </div>
                    <!-- faq content -->
                </div>
                <div class="col-lg-7">
                    <div class="about-image mt-50">
                         <img type="image/webp" src="assets/images/vesion.webp" alt="vesion" >
                        <!-- <lazy data-src=""/> -->
<!--                        <v-lazy-image src="assets/images/vesion.webp" alt="vesion" />-->

                    </div>
                    <!-- faq image -->
                </div>
            </div>
            <!-- row -->
        </div>
        <!-- container -->
    </section>
</template>

<script>
// import VLazyImage from "v-lazy-image/v2";

export default {
  components: {
    // VLazyImage
  }
};
</script>

